<template>
	<div v-if="page" class="analyse-home">
		<h2 class="text-secondary" v-html="page.title"/>

		<div class="row mb-5">
			<div class="col-12 col-lg-7 order-1 order-lg-0">
				<div v-html="page.content"/>
			</div>
			<div class="col-12 col-lg-5 order-0 order-lg-1">
				<MediaEmbed :page="page" />
			</div>
		</div>
	</div>
</template>

<script>
    // @ is an alias to /src
	import MediaEmbed from "@/components/MediaEmbed";
    import api from '@/data/api';
	import {themeMixin} from "@/mixins/chooseThemeMixin";

    export default {
        name: 'Page',
        components: {MediaEmbed},
        mixins: [themeMixin],
        props: {
            page_id: Number
        },
        data() {
            return {
                pageTitle: 'Ontdek per dimensie waar je staat',
                page: null
            }
        },
        mounted() {
            api.fetchPage(this.$props.page_id).then(response => {
                this.page = response;

                const breadcrumbs = [
                    {
                        'label': 'Home',
                        'path': '/'
                    },
                    {
                        'label': this.page.title,
                    },
                ];
                this.$store.commit('SET_BREADCRUMBS', {breadcrumbs});
            });
        }
    }
</script>
